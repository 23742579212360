import { landing } from '@corp/api'
import type { PathHelper } from '@corp/services/api'
import type { UserSortOption, UserSearchFilter } from '@corp/types/search'
import { usePage } from '@inertiajs/vue3'

export {
  currentParams,
  pushQuery,
  replaceQuery,
  saveInertiaState,
} from '@shared/url'

export function appFor (path: string) {
  path = new URL(path, location.origin).pathname

  if (path.startsWith('/admin/'))
    return 'admin'

  if (path.startsWith('/claim') || path.startsWith('/corp/redeem/'))
    return 'redemption'

  if (path.startsWith('/corp/')) {
    return Object.values(landing).some(endpoint => endpoint.pathTemplate === path)
      ? 'corporate_public'
      : 'corporate'
  }

  if (path.startsWith('/internal/'))
    return 'internal'

  if (
    path === '/'
    || path.startsWith('/about')
    || path.startsWith('/browse')
    || path.startsWith('/buy-a-gift')
    || path.startsWith('/gift-design')
    || path.startsWith('/giftagreement')
    || path.startsWith('/legal')
    || path.startsWith('/login')
    || path.startsWith('/lp')
    || path.startsWith('/my-account')
    || path.startsWith('/nlp')
    || path.startsWith('/press')
    || path.startsWith('/privacy')
    || path.startsWith('/signup')
    || path.startsWith('/support')
    || path.startsWith('/team')
  )
    return 'consumer'
}

// Public: Checks if the given path is the same Vue app
export function isSameApp (url: string) {
  try {
    const currentApp = usePage()?.props?.layout || appFor(location.pathname)
    return appFor(url) === currentApp
  }
  catch {
    return false
  }
}

// Public: Ensures a user-typed URL is treated as a standalone URL.
export function ensureProtocol (path: string) {
  try {
    const url = new URL(path)
    return path || url.origin
  }
  catch {
    return `https://${path}`
  }
}

// Public: Convenient way to set an initial value for search filters.
export function searchPathTo (helper: PathHelper, filterObj: Record<string, any>) {
  const filters: UserSearchFilter[]
    = Object.entries(filterObj).map(([id, value]) => ({ id, value }))

  const query = searchParams(filters)

  return helper.path({ query })
}

// Public: Returns query parameters for the specified search filters.
export function searchParams (filters: UserSearchFilter[], sort?: UserSortOption) {
  const filtersStr = filters.length ? JSON.stringify(filters) : null
  const sortStr = sort ? JSON.stringify(sort) : null
  return { search_filters: filtersStr, sort_option: sortStr }
}

export default {
  // blue
  primary: {
    base: '#00A2F1',
    dark: '#0491D6',
    light: '#7BBEFF',
    pale: '#E5F6FE',
  },
  // blue
  secondary: {
    base: '#00A2F1',
    dark: '#0491D6',
    light: '#7BBEFF',
    pale: '#E5F6FE',
  },
}

<script lang="ts" setup>import { computed as _computed } from 'vue';

import { Link } from '@inertiajs/vue3'
import { isSameApp } from '@corp/helpers/url'

withDefaults(defineProps<{
  href: string
}>(), {  })

const attrs = useAttrs()

const tag = _computed(() => {
  if (attrs.download || attrs.target || !isSameApp(__props.href) || String(__props.href).startsWith('mailto:')) return 'a'
  return Link
})
</script>

<template>
  <component :is="tag" v-bind="$props">
    <slot/>
  </component>
</template>

// JsFromRoutes CacheKey a31ae5d7460b194d3c8e3111b6606dc7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@corp/services/api'

export default {
  show: /* #__PURE__ */ definePathHelper('get', '/corp'),
  signin: /* #__PURE__ */ definePathHelper('get', '/corp/login'),
  letsTalk: /* #__PURE__ */ definePathHelper('get', '/corp/lets_talk'),
  privacyPolicy: /* #__PURE__ */ definePathHelper('get', '/corp/privacy_policy'),
  forgotPassword: /* #__PURE__ */ definePathHelper('get', '/corp/forgot_password'),
  sample: /* #__PURE__ */ definePathHelper('get', '/corp/sample'),
  signup: /* #__PURE__ */ definePathHelper('get', '/corp/signup'),
  termsAndConditions: /* #__PURE__ */ definePathHelper('get', '/corp/terms_and_conditions'),
  createAccount: /* #__PURE__ */ definePathHelper('post', '/corp/create_account'),
  submitLetsTalk: /* #__PURE__ */ definePathHelper('post', '/corp/submit_lets_talk'),
  updatePassword: /* #__PURE__ */ definePathHelper('post', '/corp/update_password'),
  sendPasswordReset: /* #__PURE__ */ definePathHelper('post', '/corp/send_password_reset'),
  acceptInvitation: /* #__PURE__ */ definePathHelper('get', '/corp/accept_invitation'),
  resetPassword: /* #__PURE__ */ definePathHelper('get', '/corp/reset_password'),
  verify: /* #__PURE__ */ definePathHelper('get', '/corp/verify'),
}

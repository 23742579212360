import { formatUrl, type UrlOptions } from '@js-from-routes/core'
import { router, type InertiaForm } from '@inertiajs/vue3'
import type { VisitOptions } from '@inertiajs/core'
import type { AxiosResponse } from 'axios'
import { deepDecamelizeKeys } from '@corp/helpers/object'
import { appFor, isSameApp } from '@corp/helpers/url'
import { useInertiaListener } from '@corp/composables/inertia'
import axios from '@shared/axios'

export type Method =
  | 'get'
  | 'delete'
  | 'post'
  | 'put'
  | 'patch'

/**
 * Options that can be passed to the request method.
 */
export interface RequestOptions extends VisitOptions {
  /**
   * The query string parameters to interpolate in the URL.
   */
  params?: UrlOptions

  /**
   * The body of the request, should be a plain Object or an Inertia.js form.
   */
  data?: any

  /**
   * An Inertia.js form to submit in the request.
   */
  form?: InertiaForm<any>

  /**
   * Whether to trigger an Inertia visit.
   */
  visit?: boolean

  /**
   * Whether to trigger a file download prompt with the response.
   */
  download?: boolean
}

export type Options = RequestOptions | UrlOptions

export interface PathHelper {
  <T = any>(options?: Options): Promise<T>
  path: (params?: UrlOptions) => string
  pathTemplate: string
}

/**
 * Defines a path helper that can make a request or interpolate a URL path.
 *
 * @param {Method} method  An HTTP method
 * @param {string} pathTemplate The path with params placeholders (if any).
 */
export function definePathHelper (method: Method, pathTemplate: string): PathHelper {
  const helper = <T = any>(options?: Options) => request(method, pathTemplate, options) as Promise<T>
  helper.path = (options?: UrlOptions) => formatUrl(pathTemplate, options)
  helper.pathTemplate = pathTemplate
  return helper
}

/**
 * Returns true if the object is an Inertia.js form helper.
 */
function isFormHelper (val: any, method: Method): val is InertiaForm<any> {
  return val?.hasOwnProperty('data') && val[method] // eslint-disable-line
}

// Default options for the requests, JSON is used as the default MIME type.
export const defaultRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

// Configure axios to send the current Inertia layout and organization.
axios.interceptors.request.use((request) => {
  request.headers['X-Inertia-Layout'] = (router as any).page?.props?.layout
  request.headers['X-Organization-Id'] = (router as any).page?.props?.organization?.id

  return request
})

// Configure axios to handle AJAX redirects
axios.interceptors.response.use(handleAjaxRedirects)

// Internal: Handles AJAX redirects from the AjaxRedirects concern.
async function handleAjaxRedirects (response: AxiosResponse) {
  if (response.status === 279)
    return await waitForInertia(() => visit(response.headers.location)) as any

  return response
}

// Public: Allows to perform a navigation using Inertia.
export async function visit (url: string, options?: Options) {
  if (appFor(location.pathname) && isSameApp(url))
    return request('get', url, options)
  else
    location.href = url
}

// NOTE: Inertia does not currently return the Axios promise, so we need to
// manually listen for success and error events.
async function waitForInertia (fn: Function) {
  return await new Promise((resolve, reject) => {
    const scope = effectScope(true)
    scope.run(() => {
      useInertiaListener('navigate', resolve)
      useInertiaListener('success', resolve)
      useInertiaListener('error', reject)
      useInertiaListener('exception', reject)
      useInertiaListener('finish', () => { scope.stop() })
    })
    fn()
  })
}

/**
 * Makes an AJAX request to the API server.
 * @param  {Method}  method HTTP request method
 * @param  {string}  url    May be a template with param placeholders
 * @param  {Options} options Can optionally pass params as a shorthand
 * @return {Promise} The result of the request
 */
export async function request (_method: Method, url: string, options: Options = {}): Promise<any> {
  let { params = (options.data || options), data, form = data, download, visit = !download, ...otherOptions } = options

  const config = otherOptions as VisitOptions
  const method = (options.method || _method).toLowerCase() as Method
  url = formatUrl(url, params)

  if (data) data = deepDecamelizeKeys(data)

  if (isFormHelper(form, method))
    return form[method](url, config)

  if (visit) {
    return waitForInertia(() => {
      const args = method === 'delete' ? [{ ...config, data }] : [data, config]
      router[method](url, ...args)
    })
  }

  return axios.request({
    ...config,
    data,
    method,
    url,
    headers: defaultRequestHeaders,
    responseType: download ? 'blob' : undefined,
  })
    .then((response) => {
      if (download) {
        if (!response.headers) return // Only happens in testing with `attachment` disposition.
        const contentDisposition = response.headers['content-disposition']
        downloadFile(response.data, { contentDisposition })
      }

      // Automatically unwrap JSON responses.
      return response.data ? response.data : response
    })
}

const FILENAME_REGEX = /filename="(.+)"/

// Public: Mocks a link element to download the given data
export function downloadFile (data: BlobPart, { contentDisposition = '' }) {
  const filename = contentDisposition.match(FILENAME_REGEX)?.[1] || 'download'

  const fileLink = document.createElement('a')
  fileLink.target = '_blank'
  fileLink.href = URL.createObjectURL(new Blob([data]))
  fileLink.download = filename
  fileLink.dispatchEvent(new MouseEvent('click'))
}

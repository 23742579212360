<!--
The base button component for the Giftly portal.

All components in the consumer portal should be named with a G (Giftly) prefix.
-->
<script lang="ts" setup>import { computed as _computed } from 'vue';

import GLink from '@consumer/components/GLink.vue'
import { isProduction } from '@shared/environment'

withDefaults(defineProps<{
  as?: any
  color?: 'green' | 'gold'
  danger?: boolean
  disabled?: boolean
  href?: string
  icon?: string
  iconRight?: string
  label?: string
  name: string
  primary?: boolean
  processing?: boolean
  rel?: string
  round?: boolean
  secondary?: boolean
  size?: 'small' | 'medium' | 'large'
  tertiary?: boolean
}>(), {  })

if (!isProduction) {
  watchEffect(() => {
    if (!__props.size) {
      console.error('Please specify the button size explicitly for ', __props.label || __props.name)
    }
  })
}

const componentAs = _computed(() => __props.as ?? (__props.href ? GLink : 'button'))

const variant = _computed(() => {
  if (__props.color) return __props.color
  if (__props.secondary) return 'secondary'
  if (__props.tertiary) return 'tertiary'
  if (!__props.primary && !isProduction) {
    console.error('Please specify the button variant explicitly for ', __props.label || __props.name)
  }
  return 'primary'
})
</script>

<template>
  <component
    :is="componentAs"
    :type="href ? undefined : 'button'"
    :name="name"
    :href="href"
    class="g-button flex"
    :class="[
      `g-button-${size ?? 'small'}`,
      `g-button-${variant}`,
      {
        disabled,
        processing,
        'g-button-danger': danger,
        'g-button-round': round,
        'g-button-no-label': !(label || $slots.default),
      },
    ]"
    :disabled="disabled || undefined"
    :rel="rel"
  >
    <GIcon v-if="processing" name="spinner" size="25px" class="animate-spin icon-spinner"/>
    <slot v-else>
      <GIcon v-if="icon" :name="icon"/>
      <template v-if="label">{{ label }}</template>
      <slot name="addonRight">
        <GIcon v-if="iconRight" :name="iconRight"/>
      </slot>
    </slot>
  </component>
</template>

<style lang="scss">
a.g-button {
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.g-button {
  @apply font-semibold antialiased outline-none shadow-none rounded;
  @apply justify-center items-center gap-2;
  @apply truncate;

  --height: 45px;

  background: var(--bg);
  border-color: var(--br, var(--bg));
  color: var(--color, white);
  fill: currentcolor;
  font-size: 18px;
  height: var(--height);
  stroke: currentcolor;
  transition: ease 0.5s;

  &:not(:disabled, .disabled) {
    &:hover,
    &:focus,
    &:active {
      --bg: var(--bg-focus);
    }

    // TODO: When the time is right, https://caniuse.com/?search=Relative%20Color%20Syntax
    // &:active {
    //   --bg: rgb(from var(--bg-focus) r g b / .8);
    // }
  }

  &.processing {
    @apply cursor-wait;
  }
}

.g-button-primary {
  --bg: theme("colors.primary.base");
  --bg-focus: theme("colors.primary.dark");

  &.g-button-danger {
    --bg: theme("colors.red.base");
    --bg-focus: theme("colors.red.dark");
  }

  &:disabled,
  &.disabled {
    --bg: theme("colors.grey.300");
    --color: theme("colors.grey.500");
  }
}

.g-button-secondary {
  @apply border-2 border-solid;

  --color: theme("colors.primary.base");
  --bg: white;
  --br: currentcolor;
  --bg-focus: theme("colors.primary.pale");

  &.g-button-danger {
    --color: theme("colors.red.base");
    --br: theme("colors.red.base");
    --bg-focus: theme("colors.red.pale");
  }

  &:disabled,
  &.disabled {
    --color: theme("colors.grey.500");
    --br: theme("colors.grey.400");
  }
}

.g-button-tertiary {
  @apply border-1 border-solid;

  --bg: transparent;
  --br: theme("colors.grey.400");
  --bg-focus: theme("colors.primary.pale");
  --color: theme("colors.grey.700");
  --color-focus: theme("colors.primary.base");

  &.g-button-danger {
    --bg-focus: theme("colors.red.pale");
    --color-focus: theme("colors.red.base");
  }

  &:not(:disabled, .disabled) {
    &:hover,
    &:focus,
    &:active,
    &.active {
      --br: currentcolor;
      --bg: var(--bg-focus);
      --color: var(--color-focus);
    }
  }

  &:disabled,
  &.disabled {
    @apply cursor-pointer-none pointer-events-none;
    --color: theme("colors.grey.500");

    opacity: 0.3;
  }
}

.g-button-green {
  --bg: theme("colors.green.base");
  --bg-focus: theme("colors.green.dark");
}

.g-button-gold {
  --bg: theme("colors.gold");
  --bg-focus: theme("colors.dark-gold");
  --color: theme("colors.navy");
}

.g-button-large {
  @apply px-12;

  --height: 54px;
}

.g-button-medium {
  @apply px-8;

  --height: 50px;
}

.g-button-small {
  @apply px-4;

  font-size: 14px;
}

.g-button.g-button-no-label {
  padding: initial;
  width: calc(var(--height) + 2px);
}

.g-button-round {
  @media screen and (max-width: 1023px) {
    --height: 44px;
  }

  --height: 64px;

  border-radius: 100%;
  width: var(--height);
}
</style>

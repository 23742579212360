<!--
  Allows to open dialogs programatically, ensuring they are displayed on top of
  the current page, and that they are closed when navigating away.
-->
<script setup lang="ts">
import { modals, removeModal, closeAllModals } from '@consumer/stores/modals'
import { useEventListener } from '@vueuse/core'

if (!import.meta.env.SSR) { useEventListener(document, 'inertia:navigate', closeAllModals) }
</script>

<template>
  <component
    :is="modal.component"
    v-for="modal in modals"
    :id="modal.id"
    :key="modal.id"
    v-bind="modal.attrs"
    v-on="modal.eventListeners ?? {}"
    @remove="removeModal(modal)"
  >
    <template v-for="(slot, name) in modal.slots" #[name]="slotData" :key="name">
      <component :is="slot!" v-bind="slotData"/>
    </template>
  </component>
</template>

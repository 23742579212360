import { useBreakpoints } from '@vueuse/core'

export const breakpoints = useBreakpoints({
  phone: 440,
  desktop: 1024,
})

export const isPhone = breakpoints.smaller('phone')
export const isMobile = breakpoints.smaller('desktop')
export const isDesktop = breakpoints.desktop

import { usePage as useInertiaPage } from '@inertiajs/vue3'
import type { CurrentUser, FeatureFlags, AbExperiments } from '@consumer/types/serializers'
import { toReactive } from '@vueuse/core'

type Page<T extends Record<string, any>> = Pick<ReturnType<typeof useInertiaPage<T>>, 'props' | 'url' | 'component'>

export interface Flash {
  alert?: string
  notice?: string
}

export interface SharedProps {
  abExperiments: AbExperiments
  activeHoliday?: string
  currentUser?: CurrentUser
  featureFlags: FeatureFlags
  referralSpec: string
  flash: Flash
}

export function usePage<T> (): Page<SharedProps & Record<string, any> & T> {
  const page = useInertiaPage<SharedProps & Record<string, any> & T>()
  if (page.component) { return page }

  // NOTE: Used in GCDP redesign, which is not a full Inertia app, so we inject
  // the global variables to allow components to inherit themes and such.
  return {
    props: (window as any).InertiaProps,
    url: page.url || location.href,
    component: (window as any).InertiaComponent,
  }
}

export function useRecaptcha () {
  const { props } = $(usePage())

  return {
    recaptchaType: toRef(() => props.recaptchaType as 'invisible' | 'checkbox' | undefined),
    recaptchaCheckoutSiteKey: toRef(() => props.recaptchaCheckoutSiteKey as string),
    recaptchaCheckboxSiteKey: toRef(() => props.recaptchaCheckboxSiteKey as string),
    recaptchaInvisibleSiteKey: toRef(() => props.recaptchaInvisibleSiteKey as string),
  }
}

export function useSharedData (): SharedProps {
  const page = usePage()
  return toReactive(toRef(page, 'props'))
}

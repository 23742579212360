import { formatUrl, type UrlOptions } from '@js-from-routes/core'
import {
  request,
  type Method,
  type PathHelper,
  type Options,
} from '@corp/services/api'

/**
 * Defines a path helper that can make a request or interpolate a URL path.
 *
 * @param {Method} method  An HTTP method
 * @param {string} pathTemplate The path with params placeholders (if any).
 */
export function definePathHelper (method: Method, pathTemplate: string): PathHelper {
  const helper = <T = any>(options?: Options) =>
    request(method, pathTemplate, { visit: false, ...options }) as Promise<T>
  helper.path = (options?: UrlOptions) => formatUrl(pathTemplate, options)
  helper.pathTemplate = pathTemplate
  return helper
}

import defaultTheme from '@consumer/themes/default'
import { type MaybeRef, useCurrentElement } from '@vueuse/core'

interface ThemeColorSet {
  base: string
  dark: string
  light: string
  pale: string
}

interface Theme {
  primary: ThemeColorSet
  secondary: ThemeColorSet
}

export function useDefaultTheme (el?: HTMLElement) {
  if (el) {
    const elRef = useCurrentElement<HTMLElement>()
    watch(elRef, (el) => {
      if (el) setTheme(defaultTheme, el)
    })
  }
  else {
    setTheme(defaultTheme, el)
  }
  return defaultTheme
}

export function cssPropertiesForTheme ({ primary, secondary }: Theme) {
  return {
    '--theme-color-primary-base': primary.base,
    '--theme-color-primary-dark': primary.dark,
    '--theme-color-primary-light': primary.light,
    '--theme-color-primary-pale': primary.pale,
    '--theme-color-secondary-base': secondary.base,
    '--theme-color-secondary-dark': secondary.dark,
    '--theme-color-secondary-light': secondary.light,
    '--theme-color-secondary-pale': secondary.pale,
  }
}

export function setTheme (themeRef: MaybeRef<Theme>, target?: HTMLElement) {
  const theme = toValue(themeRef)
  const properties = cssPropertiesForTheme(theme)

  // TODO: Find a way to use Inertia Head to set style properties in the body,
  // or render them in the app element instead.
  if (import.meta.env.SSR) { return }

  if (target === undefined) {
    target = document.body
  }

  Object.entries(properties).forEach(([cssProperty, value]) => {
    target?.style.setProperty(cssProperty, value)
  })
}

export function setCurrentTheme (options?: { target?: HTMLElement }) {
  const theme = defaultTheme satisfies Theme

  setTheme(theme, options?.target)

  return theme
}
